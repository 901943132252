import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { addDays, isAfter, isEqual, set, toDate } from 'date-fns';
import { useForm } from 'react-hook-form';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '../../Form';
import DatePickerField from '../../Input/DatePickerField';
import ChannelListDropdown from '../../Dropdown/ChannelListDropdown';
import Button from '../../UIComponents/Button';
import { cn } from '../../../utils';
import channelAPI from '../../../api/channelAPI';
import useApiHook from '../../../hooks/useAPI';
import { TaggingListDropdown } from '../../Tags';
import { differenceBetweenDatesInMonths } from '../../../utils/Helper';

const allChannels = { name: 'All', value: null };

export default function Filter({
  onFilter,
  timeInterval = false,
  channel = false,
  loading = false,
  feesType = false,
  tag = false,
  onChange,
  applyValidationRuleDateRange = false,
  validationRulePeriod = 3,
}) {
  const { selectedTag } = useSelector((state) => state.tag);
  const { t } = useTranslation();

  const methods = useForm({
    shouldUnregister: false,
    mode: 'onChange',
    defaultValues: {
      fromDate: new Date(),
      toDate: null,
      channel: null,
      feesType: { name: t('All'), value: null },
      tag: null,
    },
  });
  const [handler, getChannelListCall] = useApiHook(channelAPI.getChannelList);
  const optionsToDisplay = useMemo(() => {
    return handler.data ? [allChannels, ...handler.data.data] : [allChannels];
  }, [handler.data]);

  const getChannelList = () => {
    if (!selectedTag.card_brand_ids.length) {
      return;
    }
    getChannelListCall();
  };

  const fromDate = methods.watch('fromDate');
  const toDate = methods.watch('toDate');
  const feesTypeValue = methods.watch('feesType');
  const tagValue = methods.watch('tag');

  const timeIntervalsOptions = [
    { name: t('Week'), value: 'week' },
    { name: t('Month'), value: 'month' },
    { name: t('Year'), value: 'year' },
  ];

  let feesTypeOptions = [
    { name: t('All'), value: null },
    {
      name: t('reports.finance.fees.fees-types.card_replacement'),
      value: 'card_replacement',
    },
  ];

  if (selectedTag.purchase_fee_enabled) {
    feesTypeOptions = [
      ...feesTypeOptions,
      {
        name: t('reports.finance.fees.fees-types.purchase_fee'),
        value: 'purchase_fee',
      },
    ];
  }

  if (selectedTag.additional_products_enabled) {
    feesTypeOptions = [
      ...feesTypeOptions,
      {
        name: t('reports.finance.fees.fees-types.additional_product'),
        value: 'additional_product',
      },
    ];
  }

  useEffect(() => {
    getChannelList();
  }, []);

  useEffect(() => {
    methods.trigger('toDate');
  }, [fromDate, tagValue?.value]);

  useEffect(() => {
    if (typeof onChange === 'function') {
      onChange();
    }
  }, [fromDate, toDate, feesTypeValue.value]);

  useEffect(() => {
    methods.setValue(
      'channel',
      optionsToDisplay.length ? optionsToDisplay[0] : null,
    );
  }, [optionsToDisplay]);

  const isDateRangeGreaterThanXMonths = (startDate, endDate, monthsNumber) => {
    return (
      tagValue &&
      startDate &&
      applyValidationRuleDateRange &&
      differenceBetweenDatesInMonths(startDate, endDate ?? new Date()) >
        monthsNumber
    );
  };

  return (
    <Form {...methods}>
      <div className="grid grid-cols-1 md:grid-cols-4 gap-5 mb-4">
        <div className="flex flex-col">
          <FormField
            name="fromDate"
            render={(field) => (
              <FormItem>
                <FormControl>
                  <DatePickerField
                    {...field}
                    selected={field.value}
                    dateFormat="dd/MM/yyyy"
                    excludeDateIntervals={[
                      {
                        start: new Date(),
                        end: addDays(new Date(), 1),
                      },
                    ]}
                    label={t('Choose-the-date')}
                    maxDate={new Date()}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="flex flex-col">
          <FormField
            name="toDate"
            rules={{
              validate: (value) => {
                const localToDate = set(value, {
                  hours: 0,
                  minutes: 0,
                  seconds: 0,
                  milliseconds: 0,
                });
                const localFromDate = set(fromDate, {
                  hours: 0,
                  minutes: 0,
                  seconds: 0,
                  milliseconds: 0,
                });

                if (
                  tag &&
                  applyValidationRuleDateRange &&
                  validationRulePeriod > 0 &&
                  isDateRangeGreaterThanXMonths(
                    fromDate,
                    value,
                    validationRulePeriod,
                  )
                ) {
                  return t('reports.filter.select-tags.date-range-error');
                }

                return (
                  !value ||
                  isAfter(localToDate, localFromDate) ||
                  isEqual(localToDate, localFromDate)
                );
              },
            }}
            render={(field) => (
              <FormItem>
                <FormControl>
                  <DatePickerField
                    {...field}
                    selected={field.value}
                    dateFormat="dd/MM/yyyy"
                    label={`${t('End-date')}(${t('Optional')})`}
                    maxDate={new Date()}
                  />
                </FormControl>
                {applyValidationRuleDateRange && <FormMessage />}
              </FormItem>
            )}
          />
        </div>

        {channel && (
          <div className="flex flex-col w-full">
            <div className="flex items-center  py-1">{`${t('Channel')} :`}</div>
            <FormField
              name="channel"
              defaultValue={
                optionsToDisplay.length ? optionsToDisplay[0] : null
              }
              render={(field) => (
                <FormItem>
                  <FormControl>
                    <ChannelListDropdown
                      {...field}
                      options={optionsToDisplay}
                      onChangeHandler={(event) => field.onChange(event)}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        )}

        {timeInterval && (
          <div className={`flex flex-col mt-8`}>
            <FormField
              name="timeInterval"
              defaultValue={
                timeIntervalsOptions.length ? timeIntervalsOptions[0] : null
              }
              render={(field) => (
                <FormItem>
                  <FormControl>
                    <ChannelListDropdown
                      {...field}
                      options={timeIntervalsOptions}
                      onChangeHandler={(event) => field.onChange(event)}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        )}

        {feesType && (
          <div className="flex flex-col w-full">
            <div className="flex items-center  py-1">{`${t('reports.finance.fees.fees-type')}:`}</div>
            <FormField
              name="feesType"
              defaultValue={feesTypeOptions[0]}
              render={(field) => (
                <FormItem>
                  <FormControl>
                    <ChannelListDropdown
                      {...field}
                      options={feesTypeOptions}
                      onChangeHandler={(event) => field.onChange(event)}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        )}

        {tag && (
          <div className="flex flex-col">
            <div className="flex items-center  py-1">{`${t('reports.filter.select-tag')}:`}</div>
            <FormField
              name="tag"
              render={(field) => (
                <FormItem>
                  <FormControl>
                    <TaggingListDropdown
                      onChangeHandler={(event) => field.onChange(event)}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        )}

        <Button
          label={t('Go')}
          loading={
            methods.formState.isSubmitting ||
            methods.formState.isValidating ||
            handler.loading ||
            loading
          }
          onClick={methods.handleSubmit((data) => onFilter({ ...data }))}
          classAddsName={cn(
            'h-10 border flex flex-row items-center justify-center rounded hover:bg-opacity-75 font-MulishBold bg-gfCoral px-4 focus:outline-none',
            methods.formState.isValid && !handler.loading && !loading
              ? ''
              : 'opacity-50 pointer-events-none',
            tag ? '' : 'mt-8',
          )}
        />
      </div>
    </Form>
  );
}
